<template>
  <div class="min-h-contain">
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Credentials</h1>
        <Button buttonText="Add Credential" type="primary" @action="addData" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="flex mb-5 gap-5">
          <div class="w-80">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search by Name or Email</label>
            <div class="relative">
              <TextField type="text" withIcon borderEnabled v-model="selectedName" :enterKeyAction="onSearch" placeholder="Search by Name or Email" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search />
              </div>
            </div>
          </div>
          <div class="my-auto pt-5">
            <Button buttonText="Apply Filter" type="secondary" size="regular" additionalClass="py-3" @action="onSearch" />
            <Button buttonText="Clear" type="tertiary" additionalClass="py-3" @action="clearParameter" class="ml-3" />
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalData"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex gap-2">
              <Button
                type="tertiary"
                size="icon"
                :forIcon="{ color: 'yellow' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                :tooltip="{ show: true, position: '170%', text: 'Edit' }"
                @action="editMaster(props.row.id)"
              />
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { showVueToast } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      params: {
        page: 0,
        size: 10,
        q: '',
        status: ''
      },
      isResetDropdown: false,
      totalData: 0,
      isContainsSpecialCharacter: false,
      isFetching: false,
      isSearching: false,
      currentKeyword: '',
      perPage: 10,
      showProgress: true,
      stillLoading: false,
      isKeywordSameTwice: false,
      selectedName: '',
      debounce: null,
      deleteCandidate: null
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    clearParameter() {
      this.params = {
        page: 0,
        q: '',
        size: 10
      }
      this.selectedName = null
      this.initData()
    },
    initData() {
      this.showLoading()
      this.counterProgress = 0
      this.getListCredentials().then((res) => {
        this.counterProgress = 100
        this.showProgress = false
        this.stillLoading = false
        this.totalData = res.data.pagination.total
        this.hideLoading()
      })
    },
    onPageChange(params) {
      this.params.size = params.currentPerPage
      this.params.page = params.currentPage - 1
      this.initData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      return specialChars.test(str)
    },
    onSearch() {
      this.showLoading()
      this.params.page = 0
      this.params.q = this.selectedName
      this.getListCredentials().then((res) => {
        this.hideLoading()
        this.totalData = res.data.pagination.total
      })
    },
    getListCredentials() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('credentials/GET_LIST_CREDENTIALS', {
            params: queryString.stringify({
              q: this.params.q,
              size: this.params.size,
              page: this.params.page,
              status: this.params.status
            })
          })
          .then((response) => {
            if (response.data.code !== 200) return reject
            return resolve(response)
          })
      })
    },
    editMaster(id) {
      this.$router.push(`/credentials/edit/${id}`)
    },
    addData() {
      this.$router.push('/' + this.$route.meta.name + '/add')
    },
    rowStyleClassFn(row) {
      let result = 'default'
      return result
    }
  },
  computed: {
    keywordTyping() {
      return this.params.q
    },
    data: function () {
      const list = this.$store.getters['credentials/list_credentials']
      return list || []
    },
    columns: function () {
      return [
        { field: 'name', label: 'Name' },
        { field: 'clientCode', label: 'Client Code' },
        { field: 'signKey', label: 'Sign Key' },
        { field: 'slug', label: 'Slug' },
        { field: 'waNumber', label: 'CS Number' },
        { field: 'email', label: 'CS Email' },
        { field: 'status', label: 'CS Status' },
        { field: 'sequence', label: 'Sequence' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    }
  },
  watch: {
    keywordTyping(value) {
      if (this.containsSpecialChars(value)) {
        this.isContainsSpecialCharacter = true
        return
      }
      this.isContainsSpecialCharacter = false
    },
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    keyword(value) {
      if (value === this.currentKeyword) {
        if (!this.isKeywordSameTwice) this.isSearching = true
      } else {
        this.roleSelected = {}
        this.isKeywordSameTwice = true
        this.isSearching = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
