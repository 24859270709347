var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-contain"
  }, [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Credentials")]), _c('Button', {
    attrs: {
      "buttonText": "Add Credential",
      "type": "primary"
    },
    on: {
      "action": _vm.addData
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "flex mb-5 gap-5"
  }, [_c('div', {
    staticClass: "w-80"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search by Name or Email")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": "",
      "borderEnabled": "",
      "enterKeyAction": _vm.onSearch,
      "placeholder": "Search by Name or Email"
    },
    model: {
      value: _vm.selectedName,
      callback: function callback($$v) {
        _vm.selectedName = $$v;
      },
      expression: "selectedName"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1)]), _c('div', {
    staticClass: "my-auto pt-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Apply Filter",
      "type": "secondary",
      "size": "regular",
      "additionalClass": "py-3"
    },
    on: {
      "action": _vm.onSearch
    }
  }), _c('Button', {
    staticClass: "ml-3",
    attrs: {
      "buttonText": "Clear",
      "type": "tertiary",
      "additionalClass": "py-3"
    },
    on: {
      "action": _vm.clearParameter
    }
  })], 1)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalData,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex gap-2"
        }, [_c('Button', {
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              position: '170%',
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }